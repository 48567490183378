import { Box } from "@mui/material";
import APTableFilter from "../../components/table/APTableFilter";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { useNavigate } from "react-router-dom";

interface Props{
    filters?: string[];
    isReportLoading?: boolean;
    onReportAction?: () => void;
    onSearch?: ()=>void;
    onFilterItemsAction?: (val:string)=>void;
}
const AssetTableActions:React.FC<Props> = ({filters, isReportLoading, onFilterItemsAction, onSearch, onReportAction}) => {
    const navigate = useNavigate();

    const handleAddAction = ()=>navigate('/new-asset');
    return(
        <Box sx={{display:'flex', alignItems:'center', width:'100%',justifyContent:'end',gap:1}}>
            <APTableFilter
                showAdd
                isAddWhiteBackground
                showSearchPlus
                // showStatusFilter
                addText="New asset"
                addAction={handleAddAction}
                statusFilterItems={filters}
                statusFilterAction={onFilterItemsAction}
                searchPlusAction={onSearch}
            />
            <Box>
                <APIconButton
                    text={'Report'}
                    onClick={onReportAction}
                    isLoading={isReportLoading}
                    icon={
                      <Box sx={{ transform: 'rotate(-45deg)' }}>
                        <MaterialSymbol icon="send"/>
                      </Box>
                    }
                /></Box>
        </Box>
    )
}

export default AssetTableActions;