import { Text, View, Image } from '@react-pdf/renderer';

interface Props {
  assets: any[];
}

const StandardAllImage: React.FC<Props> = ({ assets }) => {
  // console.log('all images assets: ',assets)
    // const valuatonNames:any = {
    //     'flv':'Forced Liquidation Value',
    //     'fmv':'Fair Market Value',
    //     'olv':'Orderly Liquidation Value',
    //     'mov':'Market Opinion Value'
    // }
  // Function to filter out null, empty, or undefined values
  const processField = (field: any): string => {
    if (!field || field === "null" || field === "undefined" || field === "") {
      return "";
    }
    return field.toString();
  };

  // Function to create a human-readable, formatted string
  const formatAssetDescription = (asset: any): string => {
    const parts = [];

    // Combine fields in the required order
    if (asset?.year) parts.push(processField(asset.year));
    if (asset?.make) parts.push(processField(asset.make));
    if (asset?.model) parts.push(processField(asset.model));
    if (asset?.category) parts.push(processField(asset.category));
    if (asset?.serial) parts.push(`${processField(asset.serial)}`);
    if (asset?.inventory_tag) parts.push(`${processField(asset.inventory_tag)}`);
    if (asset?.sticker) parts.push(`${processField(asset.sticker)}`);
    if (asset?.unique_id) parts.push(`${processField(asset.unique_id)}`);
    if (asset?.vin) parts.push(`${processField(asset.vin)}`);
    
    (typeof asset.meters === 'string' ? JSON.parse(asset.meters) : asset.meters).forEach((reading:any)=>{
        if(reading.value !== ''){
            parts.push(reading.value);
        }
      })

    return parts.filter(part => part).join(", ");
  };

  return (
    <View style={{width:'100%'}}>
      {assets.map((asset, index) => {
        const description = formatAssetDescription(asset);

        return (
          <View key={index} style={{ borderTop:'1px solid #ccc', paddingTop:5, marginTop:'40px', width:'100%' }}>
            <Text>{description}</Text>
            <View style={{border:'1px solid #ccc', gap:30, marginTop:3, display:'flex', flexDirection:'row'}}>
                    <View style={{
                      width:'70px',
                      height:'100%',
                      backgroundColor:'#000',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center'
                    }}>
                      <Text style={{color:'#fff'}}>Values</Text>
                    </View>
                    <View style={{gap:80, display:'flex', flexDirection:'row'}}>
                      {
                        asset.estimates.map((estimate:any)=>(
                          <View>
                            <Text style={{fontSize:'10px'}}>{estimate.estimate_type.toUpperCase()}</Text>
                            <Text style={{}}>${estimate.value}</Text>
                          </View>
                        ))
                      }
                    </View>
            </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginTop: '6px',
                  gap:2
                }}
              >
                {
                  asset?.media_urls?.map((url:string)=>(
                    <Image
                    src={url}
                    style={{ width: 170, height: 150 }}
                    />
                  ))
                }
              </View>
          </View>
        );
      })}
    </View>
  );
};

export default StandardAllImage;
