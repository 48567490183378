export const EVALUATION_REASONS = [
    {
        id:'Bankruptcy',
        label: 'Bankruptcy',
        value: 'Bankruptcy'
    },
    {
        id: 'Estate Planning',
        label: 'Estate Planning',
        value: 'Estate Planning'
    },
    {
        id: 'Financing',
        label: 'Financing',
        value: 'Financing'
    },
    {
        id: 'Inspection',
        label: 'Inspection',
        value: 'Inspection'
    },
    {
        id: 'Insurance',
        label: 'Insurance',
        value: 'Insurance'
    },
    {
        id: 'Litigation',
        label: 'Litigation',
        value: 'Litigation'
    },
    {
        id: 'Loss of Life',
        label: 'Loss of Life',
        value: 'Loss of Life'
    },
    {
        id: 'Retirement',
        label: 'Retirement',
        value: 'Retirement'
    },
    {
        id: 'Trade-In',
        label: 'Trade-In',
        value: 'Trade-In'
    }
];

export const PROCESS_DATA = [
    { id:'normal', label: 'Normal', value: 'normal' },
    { id:'uspap',label: 'USPAP', value: 'uspap' },
];

export const CAPTURE_MODE_DATA = [
    { id:'onsite', label: 'On site', value: 'onsite' },
    { id:'self',label: 'Self', value: 'self' },
    {id:'desktop',value:'desktop',label:'Desktop'}
]

export const RESTRICTION_TYPE_DATA = [
    { id:'restricted', label: 'Restricted report', value: 'true' },
    { id:'non-restricted',label: 'Non-restricted report', value: 'false' },
];

export const USPAP_REPORT_VARIANTS = [
    { id:'no_image', label: 'No Image', value: 'no_image' },
    { id:'profile_image',label: 'Profile Image', value: 'profile_image' },
    { id:'all_Images',label: 'All Images', value: 'all_images' },
];

export const STANDARD_REPORT_VARIANTS = [
    { id:'profile_image',label: 'Profile Image', value: 'profile_image' },
    { id:'all_Images',label: 'All Images', value: 'all_images' },
];