import { Box } from "@mui/material";
import APDivider from "../../components/APDivider";
import { LabelInput } from "../../components/inputs";
import { DefaultButton } from "../../components/buttons";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { postAssetEstimate, getAssetEstimateByAssetId } from "./assetsQueries";
// import { isOnlyDigits, isRequired } from "../../utils/validationRules";
import { useEffect } from "react";
import { useRequestStore } from "../requests/requestStore";

interface Props {
  asset?: any;
  requestId:string;
}

const AssetEstimate: React.FC<Props> = ({ requestId,asset }) => {
  const selectedRequest = useRequestStore.getState().selectedRequest;
  // console.log(selectedRequest);
  // Fetch asset estimates using useQuery
  const { data: assetEstimates, isLoading: isFetching } = useQuery({
    queryKey: ["assetEstimate", asset?.id,],
    queryFn: () => getAssetEstimateByAssetId(requestId,asset?.id),
    enabled: !!asset, // Only run query if asset is provided
  });

  // Get the first estimate from the response (if any)
  

  // Initialize the form with the default estimate value
  const { control, handleSubmit, reset, formState:{dirtyFields} } = useForm<any>();

  // Define mutation for posting the estimate
  const { mutate: postEstimateMutation, isPending: isPosting } = useMutation({
    mutationFn: (payload: any) => postAssetEstimate(requestId,asset.id, payload),
    onSuccess: () => {
      toast.success("Estimate added successfully!");
    },
    onError: (error) => {
      toast.error("Failed to save estimate. Please try again.");
      console.error("Error posting estimate:", error);
    },
  });

  // Handle form submission
  const handleFormSubmit: SubmitHandler<any> = (data) => {
    if (!asset) {
      toast.error("Asset not provided.");
      return;
    }
    // console.log(data);
    // console.log(dirtyFields)

    const estimates = Object.keys(dirtyFields).map((key)=>({
      estimate_type:key,
      value:+data[key]
    }));

    const payload = {
      asset:asset.id,
      estimates
    }

    // console.log(payload);

    // Call mutation to post the estimate
    postEstimateMutation(payload);
  };

  useEffect(()=>{
    if(!isFetching && assetEstimates){
      // console.log('assetEstimates: ',assetEstimates)
      const initialEstimates: Record<string, number> = {};

      assetEstimates.data.forEach((estimate: any) => {
        initialEstimates[estimate.estimate_type] = estimate.value;
      });

      reset({
          ...initialEstimates
        })
    }
  },[assetEstimates, isFetching, reset])

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        borderRadius: "8px",
        px: "15px",
        pt: "15px",
      }}
    >
      <APDivider text="Enter estimate" />
      {isFetching && <Box>Getting estimate</Box>}
      {!isFetching && <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            pt: "15px",
          }}
        >
          <Box sx={{display:'flex', alignItems:'center', gap:2}}>
            {
              selectedRequest.estimate_options.map((valuation:any)=>(
                <LabelInput
                  id={valuation}
                  label={`${valuation.toUpperCase()}($)`}
                  name={valuation}
                  control={control}
                  // rules={{ validate: { isOnlyDigits } }}
                  key={valuation}
                />
              ))
            }
          </Box>
          <Box>
            <DefaultButton
              text="Save estimate"
              isRounded
              type="submit"
              isLoading={isPosting}
            />
          </Box>
        </Box>
      </form>
}       
    </Box>
  );
};

export default AssetEstimate;
