import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';
import { IReportForm } from '../reportInterface';
import DOMPurify from 'dompurify';
import { formatDate, getCurrentDate } from '../../../utils/helpers';
import { DISCLOSURES, USPAP_CERTIFICATION, USPAP_TERMS } from '../../../Constant';
import UspapProfileImage from './UspapProfileImage';
import UspapAllImage from './UspapAllImage';

interface Props{
    reportResponse: any;
    report:IReportForm;
  }
const UspapDocument = ({ reportResponse, report }:Props) => {
    console.log(reportResponse);
  const valuations = reportResponse.request.total_estimates.map((es:any)=>es.estimate_type);

  const {
    report_variant,
    introduction,
    intended_use,
    intended_user,
    effective_date,
    report_date,
    inspection_date,
    restricted,
    work_scope,
    approaches_to_value,
    market_conditions,
    sources,
    assumptions,
    hypothetical_conditions,
    limiting_conditions
  } = report;

  const sanitizeHTML = (html: string): string => {
    return DOMPurify.sanitize(html);  // Returns a sanitized string
  };

  const renderHTML = (html: string) => {
    const sanitizedHTML = sanitizeHTML(html);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHTML;
  
    return Array.from(tempDiv.childNodes).map((node, index) => {
      if (node.nodeName === 'P') {
        // Render <p> as plain text
        return <Text key={index}>{node.textContent}</Text>;
      } else if (node.nodeName === 'STRONG') {
        // Render <strong> as bold text
        return <Text key={index} style={styles.bold}>{node.textContent}</Text>;
      } else if (node.nodeName === 'EM') {
        // Render <em> as italic text
        return <Text key={index} style={{ fontStyle: 'italic' }}>{node.textContent}</Text>;
      } else if (node.nodeName === 'UL') {
        // Render <ul> as a list
        return (
          <View key={index} style={{ marginTop: '10px', display:'flex', flexDirection:'column' }}>
            {Array.from(node.childNodes).map((liNode, liIndex) => {
              if (liNode.nodeName === 'LI') {
                // Render each <li> as a bullet point
                return (
                  <View key={liIndex} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    marginTop: '10px'
                  }}>
                    <Text>•</Text>
                    <Text>{liNode.textContent}</Text>
                  </View>
                );
              }
              return null; // Handle other cases as needed
            })}
          </View>
        );
      }
      return null; // Handle other cases as needed
    });
  };

    const styles = StyleSheet.create({
        pageCenter: {
            padding: 40,
            flexDirection: "column",
            alignItems: "center",
            fontSize:12,
            fontFamily: 'Times-Roman',
            color: '#2c3033',
          },
        page:{
            padding: 40,
            flexDirection: "column",
            alignItems: "flex-start",
            fontSize:12,
            fontFamily: 'Times-Roman',
            color: '#2c3033',
        },
          logo: {
            width: "80%",
            height: "65%",
            marginBottom: 20,
          },
          sectionCenterAlign:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
          },
          section: {
            marginBottom: 20,
          },
          textCenter:{
            textAlign:'center'
          },
          bold: {
            color:'#000000',
            fontWeight:'heavy'
          },
          contact: {
            textAlign: "center",
            fontSize: 12,
            marginTop: 10,
          },
          link: {
            color: "#0073e6",
            textDecoration: "none",
          },
          table: {
            marginTop: 20,
            marginBottom:20,
            width: '100%',
          },
          tableRow: {
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
          tableCell: {
            flex: 1,
            textAlign: 'center',
            borderWidth: 1,
            borderColor: '#000',
            padding: 5,
          },
          listItem: {
            marginBottom: 10,
          },
          flexRow:{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            gap:5
          },
          gap10:{gap:10},
          gap20:{gap:20},
          marginTop10:{marginTop:10},
          marginTop20:{marginTop:20},
          textUnderline: {
            textDecoration: 'underline',
          },
          justifyBetween:{
            justifyContent:'space-between'
          },
          width100:{
            width:'100%'
          },
          pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
          },
      });

    return (
        <Document >
            {/* Page 1 */}
            <Page size="A4" style={styles.pageCenter}>
                <Image
                src="/assets/images/logo.png"
                style={styles.logo}
                />
                <View style={[styles.sectionCenterAlign]}>
                    <Text>Prepared By:</Text>
                    <Text style={styles.bold}>Purple Wave Appraisal Services</Text>
                    <Text>825 Levee Dr.</Text>
                    <Text>Manhattan, KS 66502</Text>
                </View>
                <View style={[styles.sectionCenterAlign, styles.marginTop20]}>
                    <Text style={styles.bold}>{reportResponse?.request.manager?.name}</Text>
                    <Text>Appraiser</Text>
                </View>
                <View style={styles.sectionCenterAlign}>
                    <Text style={styles.bold}>Purple Wave Appraisal Services</Text>
                    <Text>825 Levee Dr., Manhattan, KS 66502</Text>
                    <View style={[styles.flexRow, styles.gap20]}>
                        <View style={styles.flexRow}>
                            <Text style={styles.bold}>
                                Toll Free:
                            </Text>
                            <Text>
                                866-608-9283
                            </Text>

                        </View>
                        <Text>|</Text>
                        <View style={styles.flexRow}>
                            <Text style={styles.bold}>
                                Local: 
                            </Text>
                            <Text>
                                785-537-7653
                            </Text>
                        </View>
                    </View>
                    <Text style={styles.contact}>
                        Email:{" "}
                        <Link style={styles.link} src="mailto:appraisals@purplewave.com">
                        appraisals@purplewave.com
                        </Link>{" "}
                        | Website:{" "}
                        <Link
                        style={styles.link}
                        src="https://www.purplewave.com/appraisals"
                        >
                        www.purplewave.com/appraisals
                        </Link>
                    </Text>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
            
            {/* Page 2 */}
            <Page size="A4" style={styles.page}>
                <View style={[styles.section, styles.flexRow, styles.justifyBetween, styles.width100]}>
                    {/* Organization Name */}
                    <Text>{reportResponse.request.organization.name}</Text>
                    <Text>Date: {formatDate(reportResponse.created_at)}</Text>
                </View>

                {/* Salutation */}
                <Text style={[styles.section, styles.marginTop10]}>To whom it may concern:</Text>

                {/* Main Content */}
                <Text>
                    Purple Wave Appraisal Services is presenting this appraisal report on the
                    assets for <Text style={styles.bold}>{reportResponse.request.customer_name}</Text>. The purpose of this appraisal was to
                    establish the <Text>{renderHTML(introduction)}</Text>.
                </Text>
                <View style={[styles.section, styles.marginTop20]}>
                    <Text>
                        This report has been prepared in compliance with 2020-2021 Uniform
                        Standards of Professional Appraisal Practice (USPAP) standards.
                    </Text>
                </View>
                <Text>
                    The opinion of values is based on the training and experience as appraisers,
                    our company's research databases, internet, and local market searches.
                </Text>
                <View style={[styles.section, styles.marginTop20]}>
                    <Text>
                        Purple Wave Appraisal Services was informed that <Text style={styles.bold}>{reportResponse.request.customer_name}</Text>
                        owns the assets included in this report, but the appraiser makes no
                        warranties or representations as to title.
                    </Text>
                </View>
                <Text>
                    Based on the assets' condition, the prevailing economic market conditions, and
                    market analyses, it is our unbiased opinion that the value of the assets is:
                </Text>

                {/* Value Table */}
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                    {reportResponse.request.total_estimates.map((valuation: any) => (
                        <Text key={valuation.estimate_type} style={styles.tableCell}>
                        {valuation.estimate_type.toUpperCase()}
                        </Text>
                    ))}
                    </View>
                    <View style={styles.tableRow}>
                    {reportResponse.request.total_estimates.map((valuation: any) => (
                        <Text key={valuation.total_value} style={styles.tableCell}>
                        ${valuation.total_value}
                        </Text>
                    ))}
                    </View>
                </View>

                {/* Final Remarks */}
                <View style={[styles.section]}>
                    <Text>
                        These values are subject to the assumptions, definitions, and limiting
                        conditions detailed in the attached appraisal report.
                    </Text>
                </View>
                <Text>Thank you for the opportunity to be of service.</Text>

                {/* Signature */}
                <Text style={styles.marginTop20}>Sincerely,</Text>
                <Text style={{fontSize:8, marginTop:'20px', marginBottom:'10px'}}>
                    Electronic Signature Notice: This document has been transmitted
                    electronically to my client and includes my signature in electronic form. I affirm
                    that I maintain sole personal control over the use of the electronic signature
                    appended hereto. Electronically affixing my signature to this report carries the
                    same level of authenticity and responsibility for this report's content, analysis,
                    and conclusions as would applying an original ink signature on a paper copy of this report.
                </Text>
                <View style={[styles.section]}>
                    <Text>Signature</Text>
                    <Image
                        src={reportResponse.manager_signature}
                        style={{ width: 150, height: 50, marginTop:10}}
                    />
                    <Text style={styles.marginTop10}>Date signed</Text>
                    <Text>{getCurrentDate()}</Text>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                <Text style={styles.bold}>Client:</Text>
                <Text style={styles.marginTop20}>{reportResponse.request.customer_name}</Text>
                </View>

                <View style={styles.section}>
                <Text style={styles.bold}>Intended Use:</Text>
                <Text style={styles.marginTop20}>{renderHTML(intended_use)}</Text>
                </View>

                <View style={styles.section}>
                <Text style={styles.bold}>Intended User:</Text>
                <Text style={styles.marginTop20}>{intended_user}</Text>
                </View>

                <View style={[styles.section, styles.flexRow]}>
                <Text style={styles.bold}>Effective Date:</Text>
                <Text>{formatDate(effective_date)}</Text>
                </View>

                <View style={[styles.section, styles.flexRow]}>
                <Text style={styles.bold}>Report Date:</Text>
                <Text>{formatDate(report_date)}</Text>
                </View>

                <View style={[styles.section, styles.flexRow]}>
                <Text style={styles.bold}>Inspection Date:</Text>
                <Text>{formatDate(inspection_date)}</Text>
                </View>

                <View style={styles.section}>
                <Text style={[styles.bold]}>Appraisal Type:</Text>
                <Text style={styles.marginTop20}>
                    There are two types of appraisal reports. Restricted appraisal report and
                    appraisal report. Restricted appraisal report is defined as a report that is
                    restricted in that the client is the only intended user of the report.
                </Text>
                <Text style={styles.marginTop20}>
                    This report is an appraisal report; it is {restricted === 'true' ? 'a restricted' : 'not a restricted'} appraisal report.
                </Text>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.bold}>Scope of Work:</Text>
                    <Text style={styles.marginTop20}>{renderHTML(work_scope)}</Text>
                </View>
                    {report_variant === 'profile_image' && <UspapProfileImage assets={reportResponse.assets}/>}
                    {report_variant === 'all_images' &&<UspapAllImage assets={reportResponse.assets}/>}
                
                <View style={styles.section}>
                <Text style={styles.bold}>Definition of Value:</Text>
                <Text style={styles.marginTop20}>
                    The “Fair Market Value”, “Orderly Liquidation Value” and “Forced Liquidation
                    Value” as defined by the National Equipment Appraisal are:
                </Text>
                    <View style={styles.marginTop20}>
                        {valuations.includes("fmv") && (
                        <Text style={styles.listItem}>
                            <Text style={styles.bold}>• Fair Market Value:</Text> is an opinion expressed in terms of money,
                            at which the property would change hands between a willing buyer and a willing
                            seller, neither being under any compulsion to buy or to sell and both having
                            reasonable knowledge of relevant facts, as of a specific date.
                        </Text>
                        )}
                        {valuations.includes("olv") && (
                        <Text style={styles.listItem}>
                            <Text style={styles.bold}>• Orderly Liquidation Value:</Text> is an opinion of the gross amount, 
                            expressed in terms of money, that typically could be realized from a liquidation sale...
                        </Text>
                        )}
                        {valuations.includes("flv") && (
                        <Text style={styles.listItem}>
                            <Text style={styles.bold}>• Forced Liquidation Value:</Text> is an opinion of the gross amount, 
                            expressed in terms of money, that could typically be realized from a properly advertised...
                        </Text>
                        )}
                    </View>
                </View>

                <View style={styles.section}>
                <Text style={styles.bold}>Approaches to Value:</Text>
                <Text style={styles.marginTop20}>There are three approaches to value commonly used in the appraisal industry. They are:</Text>
                <View>
                    {approaches_to_value?.includes('sales_comparison') && (
                    <Text style={styles.listItem}>
                        <Text style={styles.bold}>• Sales Comparison:</Text> A procedure to conclude an opinion of value...
                    </Text>
                    )}
                    {approaches_to_value?.includes('cost') && (
                    <Text style={styles.listItem}>
                        <Text style={styles.bold}>• Cost:</Text> A procedure to estimate the current costs to reproduce...
                    </Text>
                    )}
                    {approaches_to_value?.includes('income') && (
                    <Text style={styles.listItem}>
                        <Text style={styles.bold}>• Income:</Text> A procedure to conclude an opinion of present value...
                    </Text>
                    )}
                </View>
                <Text style={styles.marginTop20}>
                    For this report, the Sales Comparison method was determined to be the best fit for
                    current fair market value. Income and Cost approaches were considered, but not
                    deemed appropriate.
                </Text>
                </View>

                <View style={styles.section}>
                <Text style={styles.bold}>Market Conditions and Markets Analyzed:</Text>
                <Text style={styles.marginTop20}>{renderHTML(market_conditions)}</Text>
                </View>

                <View style={styles.section}>
                <Text style={styles.bold}>Sources:</Text>
                <Text style={styles.marginTop20}>{renderHTML(sources)}</Text>
                </View>

                <View style={styles.section}>
                <Text style={styles.bold}>Extraordinary Assumptions:</Text>
                <Text style={styles.marginTop20}>
                    An extraordinary assumption is an assignment-specific assumption as of the 
                    effective date regarding uncertain information used in an analysis which, if 
                    found to be false, could alter the appraiser’s opinions or conclusions. 
                </Text>
                <Text style={styles.marginTop20}>{renderHTML(assumptions)}</Text>
                </View>

                <View style={styles.section}>
                <Text style={styles.bold}>Hypothetical Conditions:</Text>
                <Text style={styles.marginTop20}>
                    A hypothetical condition is defined as a condition, directly related to a specific 
                    assignment, which is contrary to what is known by the appraiser to exist on the 
                    effective date of the assignment results, but is used for the purpose of analysis.
                </Text>
                <Text style={styles.marginTop20}>{renderHTML(hypothetical_conditions)}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.bold}>Limiting Conditions:</Text>
                    { renderHTML(limiting_conditions).length > 0 && <Text style={styles.marginTop20}>{renderHTML(limiting_conditions)}</Text> } 
                </View>

                <View style={styles.section}>
                    <Text style={styles.bold}>Appraiser's Qualifications:</Text>
                    <View >{renderHTML(reportResponse?.request.manager?.bio)}</View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                <Text style={[styles.textCenter, styles.textUnderline]}>USPAP Signed Certifications</Text>
                <Text style={styles.marginTop20}>I certify that, to the best of my knowledge and belief:</Text>
                <View style={styles.section}>
                   
                    {
                        USPAP_CERTIFICATION.map((item) => (
                            <View style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                gap:10,
                                marginTop:'10px'
                            }}>
                                <Text>•</Text>
                                <Text>{item}</Text>
                            </View>
                        ))
                    }
                </View>
                <View style={[styles.section]}>
                    <Text style={{fontSize:8, marginTop:'20px', marginBottom:'10px'}}>
                        Electronic Signature Notice: This document has been transmitted
                        electronically to my client and includes my signature in electronic form. I affirm
                        that I maintain sole personal control over the use of the electronic signature
                        appended hereto. Electronically affixing my signature to this report carries the
                        same level of authenticity and responsibility for this report's content, analysis,
                        and conclusions as would applying an original ink signature on a paper copy of this report.
                    </Text>
                    <Text>Signature</Text>
                    <Image
                        src={reportResponse.manager_signature}
                        style={{ width: 150, height: 50, marginTop:10}}
                    />
                    <Text style={styles.marginTop10}>Date signed</Text>
                    <Text>{getCurrentDate()}</Text>
                </View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                <Text style={styles.bold}>Definitions and Terms from USPAP</Text>
                <Text style={styles.marginTop20}>
                    Various terms are used throughout the appraisal report. The following are definitions of the terms:
                </Text>
                <View style={styles.section}>
                    {USPAP_TERMS.map((item) => (
                    <Text key={item.term} style={{marginTop:'20px'}}>
                        <Text style={styles.bold}>{item.term}</Text> - {item.definition}
                    </Text>
                    ))}
                </View>
                </View>

                <View style={[styles.section]}>
                    <Text style={styles.bold}>USPAP Compliance Statement</Text>
                    <Text style={styles.marginTop20}>
                        This appraisal report complies with the reporting requirements as defined under Standards Rule 2-2 (b) and 8-2 (b) of the 2020-2021 Uniform Standards of Professional Appraisal Practice (USPAP) for an Appraisal Report.
                    </Text>
                </View>
                <View style={[styles.section]}>

                    <Text style={styles.bold}>Confidentiality and Privacy</Text>
                    <Text style={styles.marginTop20}>
                        Purple Wave Appraisal Services will maintain the conformity and privacy of customer information obtained in the course of this assignment in compliance with USPAP.
                    </Text>
                    <Text style={styles.marginTop20}>
                        We do not sell information about our clients to others. We protect the security and confidential information about the client. We share information outside of our company only when necessary to administer products or services we provide when we have your permission, or when required or permitted by law.
                    </Text>
                </View>
                <View style={[styles.section]}>

                <Text style={styles.bold}>Records Retention Statement</Text>
                <Text style={styles.marginTop20}>
                    An appraiser must prepare a workfile for each appraisal or appraisal review assignment. A workfile must be in existence prior to the issuance of any report or other communication of assignment results. A written summary of an oral report must be added to the workfile within a reasonable time after the issuance of the oral report.
                </Text>
                <Text style={styles.marginTop20}>The workfile must include:</Text>
                <View style={styles.section}>
                    <Text>•  The name of the client and the identity, by name or type, of any other intended users.</Text>
                    <Text>•  True copies of all written reports, documented on any type of media.</Text>
                    <Text>•  Summaries of all oral reports or testimony, or a transcript of testimony, including the appraiser’s signed and dated certification.</Text>
                    <Text>•  All other data, information, and documentation necessary to support the appraiser’s opinions and conclusions and to show compliance with USPAP, or references to the location(s) of such other data, information, and documentation.</Text>
                </View>
                <Text style={styles.marginTop20}>
                    A workfile in support of a Restricted Appraisal Report or an oral appraisal report must be sufficient for the appraiser to produce an Appraisal Report. A workfile in support of an oral appraisal review report must be sufficient for the appraiser to produce an Appraisal Review Report.
                </Text>
                <Text style={styles.marginTop20}>
                    An appraiser must retain the workfile for a period of at least five years after preparation or at least two years after final disposition of any judicial proceeding in which the appraiser provided testimony related to the assignment, whichever period expires last.
                </Text>
                <Text style={styles.marginTop20}>
                    An appraiser must have custody of the workfile, or make appropriate workfile retention, access, and retrieval arrangements with the party having custody of the workfile. This includes ensuring that a workfile is stored in a medium that is retrievable by the appraiser throughout the prescribed record retention period.
                </Text>
                </View>

                <View style={styles.section}>
                <Text style={styles.marginTop20}>
                    An appraiser having custody of a workfile must allow other appraisers with 
                    workfile obligations related to an assignment appropriate access and retrieval 
                    for the purpose of:
                </Text>
                <View>
                    <Text>•  Submission to state appraiser regulatory agencies;</Text>
                    <Text>•  Compliance with due process of law;</Text>
                    <Text>•  Submission to a duly authorized professional peer review committee; or</Text>
                    <Text>•  Compliance with retrieval arrangements.</Text>
                </View>
                <Text style={styles.bold}>Comment:</Text>
                <Text>
                    A workfile must be made available by the appraiser when required by a state appraiser 
                    regulatory agency or due process of law.
                </Text>
                <Text style={styles.marginTop20}>Workfile is available upon request.</Text>
                </View>
                <View style={[styles.section, styles.marginTop20]}>
                <Text style={styles.bold}>Disclosures:</Text>
                    <View>
                        {DISCLOSURES.map((item) => (
                        <Text key={item} style={{marginTop:'20px'}}>{item}</Text>
                        ))}
                    </View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

        </Document>
    )
};

export default UspapDocument;