import { Button, CircularProgress, SxProps } from "@mui/material";

interface Props{
    text: string;
    variant?:"text" | "outlined" | "contained";
    type?: "button" | "submit" | "reset";
    isLoading?: boolean;
    disable?:boolean;
    extrastyles?:SxProps;
    isRounded?:boolean;
    onClick?: () => void;
}

const DefaultButton: React.FC<Props> = ({text, type='button',variant='contained', isLoading, disable=false, isRounded=false, extrastyles, onClick}) => {
    return (
        <Button
            type={type}
            fullWidth
            variant={variant}
            sx={{ 
                // mt: 3, 
                // mb: 2, 
                height: '45px',
                borderRadius: isRounded?'40px':undefined, 
                ...extrastyles 
            }}
            disabled={isLoading||disable}
            startIcon={isLoading ? <CircularProgress color="inherit" size={20} sx={{marginRight:1}} /> : null}
            onClick={onClick}
            >
            {text}
        </Button>
    )
}

export default DefaultButton;