
import UspapDocument from "./UspapDocument";
import { IReportForm } from "../reportInterface";
import { PDFViewer } from "@react-pdf/renderer";

interface Props{
    reportResponse: any;
    report:IReportForm;
    onDownloadComplete?: (state:boolean)=>void;
}
const UspspReportPreview2:React.FC<Props> = ({reportResponse,report, onDownloadComplete}) => {
    
    return (
        <PDFViewer showToolbar={false} style={{ width: "100%", height: "87vh" }}>
            <UspapDocument reportResponse={reportResponse} report={report} />
        </PDFViewer>
    )

}

export default UspspReportPreview2;