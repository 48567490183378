import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface Props {
  title: string;
  content: string;
  open: boolean;
  onProceed?: () => void;
  onCancel?: () => void;
}

const WarningDialog: React.FC<Props> = ({ title, content, open, onProceed, onCancel }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', gap: 1, fontSize:'24px' }}
      >
        {title}
        <WarningAmberIcon color="warning" sx={{width:'40px',height:'40px'}}/>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onProceed} variant="contained" color="primary">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
