import { Box } from "@mui/material";
import { IColDef } from "../../components/table/tableInterfaces";
import APTable from "../../components/table/APTable";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPagination, ITableData } from "../../interfaces";

const colDef: IColDef[] = [
    { field: 'valuationType', headerName: 'Image', type: 'imageText2', imageField:'thumbnail',sortable:false, filterable:false },
    { field: 'year', headerName: 'Year', filterable:false, width:100 },
    { field: 'make', headerName:'Make', filterable:false},
    { field: 'model', headerName: 'Model', filterable:false},
    { field: 'category', headerName: 'Asset type', filterable:false },
    { field: 'serial', headerName: 'Serial',filterable:false,sortable:false },
    { field: 'sticker', headerName: 'Sticker ID',filterable:false,sortable:false },
    { field: 'miles', headerName: 'Miles',filterable:false,sortable:false },
    { field: 'media_count', headerName: 'Media',filterable:false,sortable:false },
    { field: 'status', headerName: 'Status', type:'status',filterable:false,sortable:false },
    // { field: 'myEstimate', headerName: 'My estimate ($)',filterable:false, width:130 },
    // { field: 'otherEstimates', headerName: 'Other estimates ($)', type:'imageBadge',filterable:false,sortable:false },
    { field: 'actions', headerName: 'Actions', type: 'action',filterable:false, width:130 }
];

interface Props{
    isLoading?: boolean;
    data: ITableData;
    rowPerPage?:number;
    onRowSelection?:(item:any,details:any) => void;
    onDelete?:(row:any)=>void;
    onPageChange?: (page:IPagination) => void;
}

const AssetsTable: React.FC<Props> = ({ isLoading, data, rowPerPage, onRowSelection, onDelete, onPageChange }) => {
    // console.log(data)
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openAdditionalInfoModal, setOpenAdditionalInfoModal] = useState(false);
  
    // Define actions
    const actions = [
      {
        label: 'Request additional info',
        action: (row: any) => {
          setSelectedRow(row);
          setOpenAdditionalInfoModal(true);
        }
      },
      {
        label: 'Compare with others',
        action: (row: any) => {
          // navigate(`/compare/${row.id}`);
        }
      },
      {
        label: 'Generate report',
        action: (row: any) => {}
      },
      {
        label: 'Edit asset',
        action: (row: any) => {
          navigate(`/edit-asset/${row.id}`);
        }
      },
      {
        label: 'MOVE ASSET TO TRASH',
        action: (row: any) => {
          if (onDelete) onDelete(row);
        }
      }
    ];
  
    const clickableFields = ['valuationType', 'year', 'make', 'model', 'category', 'serial', 'sticker', 'miles', 'media_count', 'status'];
  
    const handleRowClick = (row: any) => {
      if (clickableFields.includes(row.field)) {
        navigate(`/edit-asset/${row.data.id}`);
      }
    };
  
    const handleOnCancel = () => {
      setSelectedRow(null);
      setOpenAdditionalInfoModal(false);
    };
  
    // Filter and add the status to the data based on conditions
    const filteredData = data.rowData.map((item) => {
      // let status = '';
  
      // if (item.valued) {
      //   status = 'do_not_valuate';
      // } else if ((!item.valued || item.valued === null) && (!item.valuation || item.valuation === '')) {
      //   status = 'to_be_valuated';
      // } else if ((!item.valued || item.valued === null) && item.valuation && item.valuation !== '') {
      //   status = 'valuated';
      // }
  
      // Return the item with the status
      return { ...item };
    });
  
    return (
      <Box>
        <APTable
          showCheckbox
          columns={colDef}
          data={{rowData:filteredData, rowCount:data.rowCount}}
          actions={actions}
          clickableFields={clickableFields}
          rowPerPage={10}
          onRowSelection={onRowSelection}
          onRowClick={handleRowClick}
          isLoading={isLoading}
          rowHeight={90}
          onPageChange={onPageChange}
        />
        <AdditionalInfoModal open={openAdditionalInfoModal} row={selectedRow} onCancel={handleOnCancel} />
      </Box>
    );
  };
  
export default AssetsTable;