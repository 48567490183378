import { View, Text } from "@react-pdf/renderer";

interface Props{
    report:any;
}
const StandardSummary:React.FC<Props> = ({report}) => {
    const defaultValuation =
    report.request.total_estimates.find((es: any) => es.estimate_type === "fmv") ||
    report.request.total_estimates[0];
    return(
        <View style={{width:'100%'}}>
            <Text style={{textAlign:'center', fontSize:28, marginBottom:10}}>Total {defaultValuation.estimate_type.toUpperCase()}: $ {defaultValuation.total_value}</Text>
            <View style={{width:'100%', fontSize:14, display:'flex'}}>
                {
                    report.assets.map((asset:any)=>{
                        return(
                            <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', border:'1px solid #ccc', padding:5}}>
                                <View>
                                    <Text>{asset.year} {asset.make} {asset.model}</Text>
                                    <Text style={{fontSize:12, color:'#393b3d'}}>Serial: {asset.serial}</Text>
                                </View>
                                <Text>$ {asset.estimates.find((es: any)=>es.estimate_type === defaultValuation.estimate_type).value||0}</Text>
                            </View>
                        )
                    })
                }
            </View>
        </View>
    )
}

export default StandardSummary;