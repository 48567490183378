type FlattenedObject = { [key: string]: any };

export function flattenObject(obj: any, prefix = ''): FlattenedObject {
  return Object.keys(obj).reduce((acc: FlattenedObject, k: string): FlattenedObject => {
    const pre = prefix.length ? `${prefix}.${k}` : k;
    let value = obj[k];

    // Check if the value is a JSON string and parse it
    if (typeof value === 'string') {
      try {
        const parsedValue = JSON.parse(value);
        if (typeof parsedValue === 'object' && parsedValue !== null) {
          value = parsedValue;
        }
      } catch {
        // If parsing fails, keep the original string
      }
    }

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(acc, flattenObject(value, pre));
    } else {
      acc[pre] = value;
    }
    return acc;
  }, {});
}
