import { PDFViewer } from "@react-pdf/renderer";
import StandardAllImagesDocument from "./StandardAllImagesDocument";

interface Props{
    reportResponse: any;
}
const StandardReportAllImagesPreview:React.FC<Props> = ({reportResponse}) => {
    return(
        <PDFViewer showToolbar={false} style={{ width: "100%", height: "87vh" }}>
            <StandardAllImagesDocument reportResponse={reportResponse}/>
        </PDFViewer>
    )
}

export default StandardReportAllImagesPreview;