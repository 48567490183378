export const getAbbreviation = (label: string): string => {
  if (!label) return '';
  const words = label.split(' ').filter(Boolean); // Split label by spaces and remove empty strings

  if (words.length === 1) {
    // If only one word, return the first character
    return words[0][0].toUpperCase();
  }

  if (words.length === 2) {
    // If two words, return the first character of both
    return `${words[0][0]}${words[1][0]}`.toUpperCase();
  }

  // If more than two words, return the first character of the first and last words
  return `${words[0][0]}${words[words.length - 1][0]}`.toUpperCase();
};

export const getCurrentDate = (): string => {
  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = today.getDate().toString().padStart(2, '0');
  const year = today.getFullYear();

  return `${month}/${day}/${year}`;
};

// New date formatter to convert ISO string to "Month Day with suffix, Year"
export const formatDate = (isoString: string): string => {
  if(isoString === null) return '';
  const date = new Date(isoString);

  const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Add the appropriate ordinal suffix to the day
  const day = date.getDate();
  const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

  return formattedDate.replace(/\d+/, dayWithSuffix);
};

// Helper function to get the ordinal suffix for a given day
const getOrdinalSuffix = (day: number): string => {
  if (day >= 11 && day <= 13) return 'th'; // Special cases for 11th, 12th, 13th
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
