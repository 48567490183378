import { Avatar, Box, Typography } from "@mui/material";
import { ITableImageText } from "./tableInterfaces";

interface Props{
    data?: ITableImageText;
    clickable?:boolean;
    onClick?: () => void;
}
const APImageText:React.FC<Props> = ({data, clickable, onClick}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', height:'100%', gap: 1,cursor: clickable?'pointer':'default' }} onClick={onClick}>
            <Avatar alt={data?.label} src={data?.image} sx={{height:'32px', width:'32px'}} onClick={onClick}/>
            <Typography>{data?.label}</Typography>
        </Box>
    )
}

export default APImageText; 