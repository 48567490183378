import { PDFViewer } from "@react-pdf/renderer";
import StandardProfileImageDocument from "./StandardProfileImageDocument";

interface Props{
    reportResponse: any;
}
const StandardReportPreview:React.FC<Props> = ({reportResponse}) => {
    return(
        <PDFViewer showToolbar={false} style={{ width: "100%", height: "86vh" }}>
            <StandardProfileImageDocument reportResponse={reportResponse}/>
        </PDFViewer>
    )
}

export default StandardReportPreview;