import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StandardReportPreview from "./StandardReportPreview";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { STANDARD_REPORT_VARIANTS } from "../../../data/selectData";
import { RadioInput } from "../../../components/inputs";
import StandardReportAllImagesPreview from "./StandardReportAllImagesPreview";
// import Breadcrumb from "../../../components/Breadcrumb";
import { useMutation } from "@tanstack/react-query";
import { generateReport, updateReport } from "../reportQueries";
import { toast } from "react-toastify";
import { handleError } from "../../../utils/errorHandler";
import { APIconButton, DefaultButton } from "../../../components/buttons";
import StandardProfileImageDocument from "./StandardProfileImageDocument";
import StandardAllImagesDocument from "./StandardAllImagesDocument";
import { pdf } from "@react-pdf/renderer";
import { LoadingAction } from "../../../components/dialogs";
import { MaterialSymbol } from "react-material-symbols";
import Worker from 'worker-loader!../../../workers/networkWorker.ts';

const StandardReportPage = () => {
    const location = useLocation();
    // console.log(location.state.from);
    const navigate = useNavigate();
    const {control, watch, reset} = useForm();
    // const reportResponse = location.state.reportResponse;
    const [reportResponse, setReportResponse] = useState(location.state.reportResponse)

    // console.log(reportResponse)
    const reportVariant = watch('report_variant');
    const [showModal, setShowModal] = useState(false);
    // const breadcrumbItems = [
    //     { label: "Back" },
    //     { label: "Standard Report"},
    //     {label:'Standard Report Preview'}
    //   ];

    const { isPending: isLoading, mutate: postReportMutation } = useMutation({
        mutationKey: ['postReportMutation'],
        mutationFn: ({ formValues }: {formValues:any}) =>
            updateReport(reportResponse.request.id,formValues),
        onSuccess: (res) => {
            // console.log(variables);
            if(res.status === 204){
            toast.error('An error occured during request creation');
            }
            else{
            // console.log('res: ',res);
            setReportResponse(res.data);
            reset({...res.data},{keepValues:true})
            // toast.success('Report saved successfully');
            }
        },
        onError: handleError,
    });
    const { isPending: isGenerating, mutate: generateReportMutation } = useMutation({
        mutationKey: ['generateReportMutation'],
        mutationFn: ({ pdfBlob }: {pdfBlob:Blob}) =>
            generateReport(reportResponse.request.id,pdfBlob),
        onSuccess: (res) => {
        //   console.log(variables);
          if(res.status === 204){
            toast.error('An error occured during request creation');
          }
          else{
            toast.success('Report generated successfully. Kindly check your email.');
            setShowModal(false);
          }
        },
        onError: handleError,
      });

    const handleGeneratePdf = async () => {
        try{
          setShowModal(true);
          const profileDoc = <StandardProfileImageDocument reportResponse={reportResponse}/>;
          const allImageDoc = <StandardAllImagesDocument reportResponse={reportResponse}/>;
          const blob = await pdf(reportVariant === 'profile_image'?profileDoc:allImageDoc).toBlob();
          generateReportMutation({pdfBlob:blob});
          // updateInstance(<UspapDocument reportResponse={reportResponse} report={getValues() as IReportForm} />);
          // console.log(instance);

          console.log('Blob generated');
          
          // Download the PDF
        //   setShowModal(false)
        //   const link = document.createElement("a");
        //   link.href = URL.createObjectURL(blob);
        //   link.download = "Report.pdf";
        //   link.click();
        }catch(error){
          console.log(error)
        }
      };

    useEffect(()=>{
        postReportMutation({formValues:{report_variant:reportVariant}})
        // console.log('report variant called');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reportVariant])

    useEffect(()=>{
        reset({...reportResponse});
        // console.log('reset called');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const worker = new Worker();
        console.log(reportResponse);
        if(reportResponse.report_variant ==='all_images'){
          console.log(reportResponse.assets);
          worker.onmessage = (event) => {
            const { status, data, error } = event.data;
            if (status === 'success') {
              console.log('worker response: ',data)
              // setData(data);
            } else {
              console.log('an error occured: ',error)  
              // setError(error);
            }
          };
      
          // Send a request to the worker
          worker.postMessage({
            url: 'https://jsonplaceholder.typicode.com/posts',
            assets: reportResponse.assets,
            options: { method: 'GET' },
          });
        }
    
        // Handle messages from the worker
    
        // Cleanup the worker
        return () => {
          worker.terminate();
        };
      }, [reportResponse]);

    return (
        <Box sx={{pl:'11px',pt:'16px',pr:'30px'}}>
            
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                <Box>
                    <RadioInput
                        name="report_variant"
                        control={control}
                        options={STANDARD_REPORT_VARIANTS}
                    />
                </Box>
                <Box sx={{display:'flex', alignItems:'center', gap:2}}>
                    <DefaultButton 
                            text='Download report'
                            onClick={handleGeneratePdf}
                            isLoading={isLoading}
                        />
                        <APIconButton
                        text='Cancel'
                        isRounded={false}
                        color={'secondary'}
                        icon={<MaterialSymbol icon='delete' />}
                        isDisabled={isGenerating}
                        onClick={() => {navigate(location.state.from)}}
                    />
                </Box>

            </Box>
            {reportVariant === 'profile_image' &&<StandardReportPreview reportResponse={reportResponse} />}
            {reportVariant === 'all_images' &&<StandardReportAllImagesPreview reportResponse={reportResponse} />}
            <LoadingAction open={showModal} title="Compiling Data" content="Compliling report data. Please wait..."/>
        </Box>
    )

}

export default StandardReportPage;