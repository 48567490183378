import Box from "@mui/material/Box";
import APDivider from "../../components/APDivider";
import { Control } from "react-hook-form";
import { LabelInput, RichTextInput } from "../../components/inputs";
// import APDateInput from "../../components/inputs/DateInput";

interface Props{
    control: Control<any>;
}

const IntendedUse:React.FC<Props> = ({control}) => {
    return (
        <Box>
            <APDivider text="Intended use" margin={'40px 0px'} />
            <Box>
                <RichTextInput id="intended_use" name="intended_use" control={control} />
                <Box sx={{display:'flex', alignItems:'center', gap:'10px',mt:'40px'}}>
                    <LabelInput
                        id="intended_user"
                        label="Intended user"
                        name="intended_user"
                        control={control}
                    />
                    {/* <APDateInput 
                        id="effective_date"
                        label="Effective date"
                        name="effective_date"
                        control={control}
                    /> */}
                    <LabelInput
                        id="effective_date"
                        label="Effective date"
                        name="effective_date"
                        control={control}
                    />
                    <LabelInput
                        id="report_date"
                        label="Report date"
                        name="report_date"
                        control={control}
                    />
                    <LabelInput
                        id="inspection_date"
                        label="Inspection date"
                        name="inspection_date"
                        control={control}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export default IntendedUse;