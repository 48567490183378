import { Box, Typography, Menu, MenuItem, IconButton, Avatar } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState, useMemo, useEffect } from "react";
import { useAuthStore } from "../features/auth/authStore";
import { useFetchOrganizations } from "../hooks/useFetchOrganizations";
import { IOrganization } from "../features/organization-manageent/organizationInterfaces";

export interface IOrgSelect {
  id: string;
  name: string;
  default_estimate_options: string[];
  logo?: string;
}

interface Props {
  onSelect?: (val: IOrgSelect) => void;
}

const OrganizationSelector: React.FC<Props> = ({ onSelect }) => {
  const [fetchOrg, setFetchOrg] = useState(false);
  const authUser = useAuthStore((state) => state.authUser);
  const { data: organizations } = useFetchOrganizations(fetchOrg);
  const [orgMenuAnchorEl, setOrgMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOrg, setSelectedOrg] = useState<IOrgSelect>({
    id: "all",
    name: "All",
    default_estimate_options: [],
    logo:"/assets/images/org.png"
  });

  const orgData = useMemo(() => {
    const allOption = { id: "all", name: "All", default_estimate_options: [] };

    if (authUser?.role === "admin" && authUser.organizations) {
      const adminOrgs = authUser.organizations.map((org) => ({
        id: org.id,
        name: org.name,
        default_estimate_options: org.default_estimate_options,
        logo:org.logo
      }));
      return [allOption, ...adminOrgs];
    }

    const fetchedOrgs: IOrgSelect[] =
      organizations?.data?.map((org: IOrganization) => ({
        id: org.id,
        name: org.name,
        default_estimate_options: org.default_estimate_options,
        logo:org.logo
      })) || [];

    return [allOption, ...fetchedOrgs];
  }, [authUser, organizations]);

  const handleOrgMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setOrgMenuAnchorEl(event.currentTarget);

  const handleOrgMenuClose = (org: IOrgSelect) => {
    if (org.id !== selectedOrg.id) {
      setSelectedOrg(org);
      if (onSelect) onSelect(org);
    }
    setOrgMenuAnchorEl(null);
  };

  useEffect(() => {
    if (authUser?.role === "super_admin" || authUser?.role === "appraiser") {
      setFetchOrg(true);
    } else if (authUser?.role === "admin") {
      setFetchOrg(false);
    }
  }, [authUser]);

  const isSelectorEnabled =
    authUser?.role === "super_admin" || authUser?.role === "appraiser";

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Avatar alt={selectedOrg.name} src={selectedOrg.logo} sx={{height:'36px', width:'36px'}}/>
      {/* <img
        src={selectedOrg.logo??'/assets/images/org.png'}
        alt="org"
        width={40}
        height={40}
      /> */}
      <Typography sx={{ opacity: isSelectorEnabled ? 1 : 0.5 }}>
        {selectedOrg.name}
      </Typography>
      {/* {isSelectorEnabled && ( */}
        <IconButton onClick={handleOrgMenuOpen}>
          <ArrowDropDownIcon />
        </IconButton>
      {/* )} */}
      <Menu
        anchorEl={orgMenuAnchorEl}
        open={Boolean(orgMenuAnchorEl)}
        onClose={() => setOrgMenuAnchorEl(null)}
      >
        {orgData.map((org) => (
          <MenuItem key={org.id} onClick={() => handleOrgMenuClose(org)}>
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default OrganizationSelector;
