import { Box, useTheme } from "@mui/material";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
  status: string;
}

const APStatus: React.FC<Props> = ({ status }) => {
  const theme = useTheme();

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const getStatus:{
    color?: any;
    icon: any;
    text: any;
    fill?: boolean;
  } = (() => {
    if(!status) return {
      icon: "help", // Provide a default icon
      text: "Unknown",
    };
    // if (typeof status === "boolean") {
    //   return {
    //     color: status ? theme.palette.completed_color : theme.palette.secondary.main,
    //     icon: status ? "check_circle" : "close",
    //     text: status ? "Active" : "Inactive",
    //   };
    // }

    switch (status.toLowerCase()) {
      case "new":
      case 'scheduled':
      case 'to_be_valuated':
        return {
          color: theme.palette.new_color,
          icon:"brightness_empty",
          fill:false,
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
      case "in progress":
      case "in_progress":
      case 'pending':
      case 'capture_in_progress':
        return {
          color: theme.palette.in_progress_color,
          icon:"clock_loader_40",
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
      case "awaiting_feedback":
      case "in_complete":
        return {
          color: theme.palette.awaiting_feedback_color,
          icon:'pending',
          fill:true,
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
      case "completed":
      case "active":
      case "valuated":
        return {
          color: theme.palette.completed_color,
          icon:"check_circle",
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
      case "blocked":
      case 'do_not_valuate':
        return {
          color: '#D5393C',
          icon: "cancel",
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
      case "locked":
        return {
          color: '#E48731',
          icon:'lock',
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
      case "revoked":
        return {
          color: '#FF5252',
          icon: "error",
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
      default:
        return {
          color: "#ccc",
          icon: "help",
          text: capitalizeFirstLetter(status.replaceAll('_',' '))
        };
    }
  })();

  return (
    <Box
      sx={{
        backgroundColor: getStatus.color,
        color: ["New",'new','scheduled','to_be_valuated'].includes(status) ? "" : "#ffffff",
        display: "flex",
        alignItems: "center",
        gap: "6px",
        width: "fit-content",
        padding: "0px 8px",
        borderRadius: "6px",
        height: "24px",
      }}
    >
      <MaterialSymbol icon={getStatus.icon} fill={getStatus.fill??true}/>
      {/* <img
        src={`/assets/icons/${getStatus.icon}.png`}
        width={"14px"}
        height={"14px"}
        alt={getStatus.text}
      /> */}
      {getStatus.text}
    </Box>
  );
};

export default APStatus;
