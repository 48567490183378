import React from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BreadcrumbItem {
  label: string;
  path?: string;
  data?: any;
  onClick?: ()=>void;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();

  const handleClick = (label?: string, path?: string, data?: any, onClick?: ()=>void) => {
    if(onClick){
        onClick();
    }
    else if(label === 'Back'){
        navigate(-1);
    }
    else{
        if (path) {
          navigate(path, { state: { data } });
        }
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        return isLast ? (
          <Typography key={index} color="text.primary">
            {item.label}
          </Typography>
        ) : (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: item.path ? "pointer" : "default",
              color: "inherit",
            //   textDecoration: item.path ? "underline" : "none",
            //   "&:hover": { textDecoration: item.path ? "underline" : "none" },
            }}
            onClick={() => handleClick(item.label,item.path, item.data, item.onClick)}
          >
            {/* <MaterialSymbol icon="star" fill size={16} /> */}
            <Typography>{item.label}</Typography>
          </Box>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
