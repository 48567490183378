import { appAPI } from "../../httpClient";
import { IResetPasswordForm } from "../auth/authInterfaces";
import { IUserForm } from "./userInterfaces";

export const getUsers = async(params?:any) => {
    const res = await appAPI.get('/users',{params});
    return {
        meta:res.data.meta,
        data:res.data.data
    };
}
export const getUser = async(userId:string) => appAPI.get(`/users${userId}`);
export const postUser = async(formValues:IUserForm) => appAPI.post('/invitations',formValues);
export const updateUser = async(userId:string,formValues:IUserForm,headers?:any) => appAPI.patch(`/users/${userId}`,formValues, {headers});
export const deleteUser = async(userId:string)=> appAPI.delete(`/api/v1/users/${userId}`);
export const postResentInvitation = async(userId:string,formValues:IUserForm) => appAPI.post(`/invitations/resend_invitation`,formValues);
export const revokeInvitation = async(userId:string,formValues:IUserForm) => appAPI.post(`/invitations/revoke_invitation`,formValues);
export const setPassword = async (formValues: IResetPasswordForm) => appAPI.post('/users/accept_invite', formValues);