import { Box, Typography, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { ITableAction } from "./tableInterfaces";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAuthStore } from "../../features/auth/authStore";

interface Props {
  onOpenMenu?: (event: any) => void;
  onCloseMenu?: () => void;
  actions?: ITableAction[];
  isComplete?: boolean;
  row?: any;
  menu?: any;
}

// Helper function to determine if an action is disabled
const isActionDisabled = (status: string, label: string, isCurrentUser?: boolean): boolean => {
  const lowerLabel = label.toLowerCase();
  const disabledConditions: Record<string, string[]> = {
    'completed': ['edit request', 'delete'],
    'active': ['resend invite', 'reset password','revoke'],
    'blocked': ['resend invite', 'reset password','revoke'],
    'pending': ['block', 'reset password'],
    'locked': ['resend invite', 'block','revoke'],
    'revoked': ['block','reset password','block','revoke']
  };

  const defaultDisabled = ['generate report'];

  // if (defaultDisabled.includes(lowerLabel)) return true;
  // return disabledConditions[status]?.some(cond => lowerLabel.includes(cond)) ?? false;

  if (status === 'active' && isCurrentUser && lowerLabel.includes('block')) {
    return true;
  }
  
  if (disabledConditions[status]?.some((cond) => lowerLabel.includes(cond))) {
    return true;
  }

  // Apply defaultDisabled only if no specific status exemptions exist
  return status === 'news' && defaultDisabled.includes(lowerLabel);
};

// Helper function to modify label based on status
const getUpdatedLabel = (status: string, label: string): string => {
  const lowerLabel = label.toLowerCase();
  if (status === 'active' && lowerLabel.includes('block/unblock user')) return 'Block user';
  if (status === 'blocked' && lowerLabel.includes('block/unblock user')) return 'Unblock user';
  return label;
};

const renderMenuItem = (
  action: ITableAction,
  row: any,
  isDisabled: boolean,
  label: string,
  onCloseMenu?: () => void,
  theme?: any // Pass the theme as an argument
) => {
  return (
    <MenuItem
      key={action.label}
      sx={{
        backgroundColor: action.label.includes('TRASH') ? `${theme.palette.error.main}20` : null,
        borderRadius: action.label.includes('TRASH') ? '8px' : null,
        pointerEvents: isDisabled ? 'none' : 'auto',
        opacity: isDisabled ? 0.5 : 1,
        marginBottom: '4px',
        fontSize: '14px',
      }}
      onClick={() => {
        if (!isDisabled) {
          action.action(row);
          onCloseMenu?.();
        }
      }}
    >
      {label}
    </MenuItem>
  );
};

const APActionCell: React.FC<Props> = ({ onCloseMenu, onOpenMenu, actions = [], row, menu }) => {
  const currentUserId = useAuthStore((state) => state.authUser?.id);
  const theme = useTheme();
  const status = row?.status?.toLowerCase() || '';
  const isCurrentUser = row?.id === currentUserId;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '24px',
          backgroundColor: `${theme.palette.black}10`,
          height: '24px',
          width: '85px',
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>Options</Typography>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          sx={{ fontSize: '16px', padding: 0 }}
          onClick={onOpenMenu}
        >
          {menu.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={menu.anchorEl}
        keepMounted
        open={menu.open}
        onClose={onCloseMenu}
        sx={{ padding: '0px 20px' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              width: '251px',
              borderRadius: '16px',
              padding: '8px',
              boxShadow: `2px 2px 4px ${theme.palette.black}10`,
            },
          },
        }}
      >
        {actions.map((action) => {
          const isDisabled = isActionDisabled(status, action.label, isCurrentUser);
          const label = getUpdatedLabel(status, action.label);

          return renderMenuItem(action, row, isDisabled, label, onCloseMenu, theme);
        })}
      </Menu>
    </Box>
  );
};

export default APActionCell;
