import { Box, Typography, useTheme } from "@mui/material";
import { APIconButton } from "../../components/buttons";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from "react-router-dom";
// import { useRequestStore } from "../requests/requestStore";
import { useEffect, useState } from "react";

interface Props{
    request?: any;
}
const RequestInfo:React.FC<Props> = ({request}) => {
    // console.log(request)
    const theme = useTheme();
    const navigate = useNavigate();

    // const selectedRequest = useRequestStore.getState().selectedRequest;
    const [reqInfo,setReqInfo]= useState<any>({});
    
    useEffect(()=>{
        // console.log('in request info: ',request);

        const name = request.customer_name;
        const email = request.customer_email;
        const assets_count = request.assets_count;
        const valuations = request.total_estimates??[];
        const manager = request?.manager?.name??'NA';
        const collaborators = request.collaborators?.length>0?`+ ${request.collaborators.length} collaborators`:'';


        setReqInfo({name,email,assets_count,valuations,manager,collaborators});

    },[request])
     const renderTitle = (title:string, sub:string)=>(
        <Box>
            <Typography sx={{fontSize:'18px', color:`${theme.palette.black}87`, fontWeight:'bold'}}>{title}</Typography>
            <Typography sx={{fontSize:'14px', color:`${theme.palette.black}60`}}>{sub}</Typography>
        </Box>
     )

     const renderRowItem = (icon:string, text:string) => (
        <Box sx={{display:'flex', alignItems:'center'}}>
            <img src={`/assets/icons/${icon}`} alt={icon} width={24} height={24} />
            <Typography sx={{fontSize:'14px', color:`${theme.palette.black}60`}}>{text}</Typography>
        </Box>
     )
    return(
        <Box sx={{
            height:'66px',
            backgroundColor:`${'#919EAB'}10`,
            padding:`10px ${theme.spacing(3)}`,
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
        }}>

        <Box sx={{display:'flex', width:'80%', justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center', gap:'40px'}}>
                {renderTitle('Request Summary',`${reqInfo.name}  |  ${reqInfo.email}`)}
                    {renderTitle('Appraisers',`${reqInfo.manager} ${reqInfo.collaborators}`)}
                {reqInfo.valuations?.map((valuation:any)=>(
                    <Box key={valuation.estimate_type} sx={{display:'flex', alignItems:'center'}}>
                        <img src={`/assets/icons/attach_money.png`} alt={'attach_money.png'} width={24} height={24} />
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <Typography sx={{fontSize:'14px', color:`${theme.palette.black}60`}}>{valuation.estimate_type.toUpperCase()}:</Typography>
                            <Typography sx={{fontSize:'14px', color:`${theme.palette.black}`}}>{valuation.total_value}</Typography>
                        </Box>
                    </Box>
                ))}
                {renderRowItem('list_numbered.png', `${reqInfo.assets_count} assets`)}
            </Box>
        </Box>

        <Box>
            <APIconButton 
                isRounded
                whiteBackground
                icon={<BorderColorIcon />}
                text="Edit request"
                onClick={()=>{navigate(`/edit-request/${request.id}`)}}
            />
        </Box>
        </Box>
    )

}

export default RequestInfo;