import { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import APDivider from '../../components/APDivider';
import { LabelInput, LabelCheckboxInput, RichTextInput, SelectInput, TextareaInput } from '../../components/inputs';
import ImagePreview from './ImagePreview';
import { useCategoryStore } from '../../hooks/useCategoryStore';
import { MaterialSymbol } from 'react-material-symbols';
import { APIconButton, DefaultButton } from '../../components/buttons';
import JSZip from 'jszip';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AssetFormValues, IAssetPayload, IImage, UpdateAssetVariables } from './assetsInterface';
import { deleteAsset, getAssetById, updateAsset } from './assetsQueries';
import { toast } from 'react-toastify';
import { handleError } from '../../utils/errorHandler';
import { useRequestStore } from '../requests/requestStore';
import ImagePreviewModal from './ImagePreviewModal';
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmationDialog';
import AssetComment from '../comments/AssetComment';
import AssetEstimate from './AssetEstimate';
import AssetSummary from './AssetSummary';
import { useGeneralStore } from '../../generaStore';
import Breadcrumb from '../../components/Breadcrumb';

const EditAssetForm = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const selectedRequest = useRequestStore.getState().selectedRequest;
  // console.log('selected req: ', selectedRequest);

  const { data: assetData, isLoading } = useQuery(
    {
      queryKey:['asset', id], 
      queryFn:() => getAssetById(selectedRequest.id,id as string), 
      enabled: !!id,
    }
  );

  const { mutate: deleteAssetMutation, isPending: isAssetDeleting } = useMutation({
    mutationFn: (assetId: string) => deleteAsset(assetId),
    onSuccess: () => {
      toast.success("Successfully moved to trash");
      navigate(-1);
    },
    onError: (error) => handleError(error),
  });

  const { mutate: updateAssetMutation } = useMutation({
    mutationKey: ['updateAssetMutation'],
    mutationFn: ({ assetId, formValues }: UpdateAssetVariables) =>
      updateAsset(selectedRequest.id ,assetId,formValues),
    onSuccess: (res,variables) => {
      console.log(variables);
      if(res.status === 204){
        toast.error('An error occured during asset creation');
        setIsUpdating(false);
      }
      else{
        toast.success('Asset updated successfully');
        if(attachedImages.length>0) toast.success('Media upload in progress. Kindly refresh later to see updates.');
        navigate(-1);
      }
    },
    onError: (error:any)=>{
      setIsUpdating(false);
      handleError(error);
    },
  });
  
  // const selectedRequest = useRequestStore.getState().selectedRequest;
  const setIsPageTransition = useGeneralStore((state) => state.setIsPageTransition);
  const { control, handleSubmit, reset, setValue, watch } = useForm<any>();
  const { categories, fetchCategories } = useCategoryStore();
  const [showAllImages, setShowAllImages] = useState(false);
  const [attachedImages, setAttachedImages] = useState<IImage[]>([]);
  // const [assetImages, setAssetImages] = useState<IImage[]>([]);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  
  const valued = watch("valued");
  const zipCode = watch("zip");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [displayedImages, setDisplayedImages] = useState<any[]>([]);
  const [openCommentPanel, setOpenCommentPanel] = useState(false);
  const [meterReading, setMeterReading] = useState<any[]>([]);


  useEffect(() => {
    setIsPageTransition(isLoading);
    // console.log('assetData',assetData);
    
    if (assetData) {
      // console.log('asset response: ', assetData?.data.data);
      const { location } = assetData;
      const res:any = { ...assetData };
  
      if (location) {
        const locationParts = location.split('|');
        // console.log(locationParts);
  
        res.zip = locationParts.length > 0 ? locationParts[0] : '';
        res.state = locationParts.length > 1 ? locationParts[1] : '';
        res.city = locationParts.length > 2 ? locationParts[2] : '';
        res.street = locationParts.length > 3 ? locationParts[3] : '';
      }

      setDisplayedImages(res.media??[])
      res.category = categories.find((item)=>item.label===res.category);
      res.valued = res.valued?['valued']:[];

      (typeof assetData.meters === 'string' ? JSON.parse(assetData.meters) : assetData.meters).forEach((reading:any)=>{
        res[reading.units]=reading.value
      })

      setMeterReading(typeof assetData.meters === 'string' ? JSON.parse(assetData.meters) : assetData.meters);
      reset(res);
    }
    return () => setIsPageTransition(false);
  }, [assetData, categories, isLoading, reset, setIsPageTransition]);
  
  const onSubmitWithActionType = () => {
    return async (data: AssetFormValues) => {
      setIsUpdating(true);
      // console.log('Form Data:', data);

      const {vin,category, sticker, serial, model, make, year,zip:zipcode, city, state, street, unique_id, inventory_tag, valued,notes} = data;
      const payload = {
        vin,
        category:category?.label,
        sticker,
        serial,
        model,
        make,
        year,
        unique_id,
        inventory_tag,
        location: `${zipcode}|${state}|${city}|${street}`,
        valued: (valued ?? []).length > 0,
        notes
      }

      const newMeter = meterReading.map((meter:any)=>({
        units:meter.units,
        value:data[meter.units]
      }));
   
      const zip = new JSZip();
  
      for (const image of attachedImages) {
        const response = await fetch(image.url);
        const blob = await response.blob();
        zip.file(image.filename, blob);
      }
  
      const zipBlob = await zip.generateAsync({ type: 'blob' });
  
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        const value = payload[key as keyof IAssetPayload];
        
        formData.append(`asset[${key}]`, value as string);
      });
      formData.append('asset[meters]',JSON.stringify(newMeter));
  
      formData.append('asset[media_zip]', zipBlob, 'media_zip.zip');
      formData.append('request',selectedRequest.id)

      updateAssetMutation({assetId:id!,formValues:formData});
      
    };
  };
  
  const handleAttachCompressedImages = (compressedImages: IImage[]) => {
    // console.log(displayedImages, compressedImages);
    setDisplayedImages((prev)=>[...prev,...compressedImages])
    setAttachedImages(compressedImages);
    setOpenImagePreview(false);
    setShowAllImages(false);
  };

  const handleFormSubmit = () => {
    handleSubmit(onSubmitWithActionType())();
  };

  const handleDelete = () => {
    deleteAssetMutation(id!);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleImagePreviewClose = () => {
    setOpenImagePreview(false);
    setShowAllImages(false);
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddField = () => {
    if(inputValue === '') return;
    setMeterReading((prev)=>[...prev,{units:inputValue}]);
    setValue(inputValue,null);
    setInputValue('');
    setOpenModal(false);
  };

  useEffect(() => {
    if (zipCode?.length === 5) {
      fetch(`https://api.zippopotam.us/us/${zipCode}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.places && data.places.length > 0) {
            setCity(data.places[0]['place name']);
            setState(data.places[0]['state abbreviation']);
            setValue("city", data.places[0]['place name']);
            setValue("state", data.places[0]['state abbreviation']);
          }
        })
        .catch((error) => console.error('Error fetching location data:', error));
    }
    else{
      setValue("city", "");
      setValue("state", "");
      // setValue("street", "");
    }
  }, [zipCode, setValue]);

  const handleViewAllImages = () => {
    setShowAllImages(true);
    setOpenImagePreview(true);
    
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const breadcrumbItems = [
    { label: "Request", path: "/requests" },
    { label: "Asset", path: `/requests/${selectedRequest?.id}/assets`, data:{} },
    { label: assetData?.id }, // No path for the current page
  ];

  return (
    <Box sx={{ padding: (theme) => theme.spacing(3) }}>
      <Box sx={{mb:'20px'}}>
        <Breadcrumb items={breadcrumbItems} />
      </Box>
      {assetData && <AssetSummary  asset={assetData}/>}
      <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', gap:2, width:'100%',mt:'40px', mb:'36px'}}>
        <APIconButton
          text='Asset comment'
          whiteBackground
          icon={<MaterialSymbol icon='add_comment' />}
          isDisabled={false}
          onClick={() => {
            setOpenCommentPanel(true);
            console.log('open com')
          }}
        />
        <APIconButton
          text='Compare'
          whiteBackground
          icon={<MaterialSymbol icon='swap_vert' />}
          isDisabled
          onClick={() => {}}
        />
        <APIconButton
          text='Request additional data'
          whiteBackground
          icon={<MaterialSymbol icon='upload' />}
          isDisabled
          onClick={() => {}}
        />
        <APIconButton
          text='Share report'
          icon={<MaterialSymbol icon='upload' />}
          isDisabled
          onClick={() => {}}
        />
      </Box>
      <Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box sx={{ width: '80%' }}>
              {valued?.length===0 && <AssetEstimate asset={assetData} requestId={selectedRequest.id}/>}

              <Box sx={{mt:'55px'}}>
                <APDivider text="Valuation details" />
                  <Box sx={{ display: 'flex', width: '50%' }}>
                    <LabelCheckboxInput
                      label="Default valuation types"
                      name="valued"
                      control={control}
                      options={[
                        { label: 'List/No asset value', value: 'valued' },
                      ]}
                    />
                  </Box>
              </Box>

              {/* Equipment details */}
              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Equipment details" />
                <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
                  <LabelInput id="year" label="Year" name="year" control={control} />
                  <LabelInput id="make" label="Make" name="make" control={control} />
                  <LabelInput id="model" label="Model" name="model" control={control} />
                </Box>
                <Box sx={{ width: '32%' }}>
                  <SelectInput
                    id="category"
                    label="Asset type"
                    name="category"
                    options={categories}
                    control={control}
                  />
                </Box>
              </Box>

              {/* Asset location */}
              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Asset location" />
                <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
                  <LabelInput id="zip" label="Zip" name="zip" control={control} />
                  <LabelInput id="state" label="State" name="state" disabled control={control} defaultValue={state} />
                  <LabelInput id="city" label="City" name="city" disabled control={control} defaultValue={city} />
                </Box>
                <Box sx={{ width: '32%' }}>
                  <TextareaInput id="street" label="Street" name="street" control={control} />
                </Box>
              </Box>

              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Identification numbers" />
                  <Box 
                    // sx={{ display: 'flex', width: '100%', gap: 3}}
                    sx={{
                      display: "grid",
                      width: "100%",
                      gap: 3,
                      gridTemplateColumns: "repeat(3, 1fr)", // 3 equal columns
                    }}
                    >
                    <LabelInput
                      id="serial"
                      label="Serial number"
                      name="serial"
                      control={control}
                    />
                    <LabelInput
                      id="vin"
                      label="VIN"
                      name="vin"
                      control={control}
                    />
                    <LabelInput
                      id="sticker"
                      label="Sticker"
                      name="sticker"
                      control={control}
                    />
                    <LabelInput
                      id="unique_id"
                      label="Unique Id"
                      name="unique_id"
                      control={control}
                    />
                    <LabelInput
                      id="inventory_tag"
                      label="Inventory tag"
                      name="inventory_tag"
                      control={control}
                    />
                  </Box>
              </Box>
              
              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Service meter reading" />
                <Box
                  sx={{
                    display: "grid",
                    width: "100%",
                    gap: 3,
                    gridTemplateColumns: "repeat(3, 1fr)", // 3 equal columns
                  }}
                >
                  {meterReading.map((reading: any) => (
                    <LabelInput
                      key={reading.units}
                      id={reading.units}
                      label={reading.units}
                      name={reading.units}
                      control={control}
                    />
                  ))}
                  {true && (
                    <Box sx={{ width: "100%", mt: "22px" }}>
                      <Button
                        variant="text"
                        sx={{
                          backgroundColor: "#F2F2F2",
                          color: "#19161D",
                          borderRadius: "50px",
                          padding: "10px 16px",
                          fontSize: "14px",
                        }}
                        endIcon={<MaterialSymbol icon="add" />}
                        onClick={handleOpenModal}
                      >
                        Add field
                      </Button>
                    </Box>
                  )}
                </Box>

                  {/* <Box sx={{ display: 'flex', width: '100%', gap: 3}}>
                  {meterReading.map((reading: any) => (
                      <LabelInput
                        key={reading.units}
                        id={reading.units}
                        label={reading.units}
                        name={reading.units}
                        control={control}
                      />
                    ))
                  }
                    {true && <Box sx={{width:'100%',  mt:'22px'}}>
                      <Button
                        variant="text"
                        sx={{
                          backgroundColor:'#F2F2F2',
                          color:'#19161D',
                          borderRadius:'50px',
                          padding:'10px 16px',
                          fontSize:'14px'
                        }}
                        endIcon={<MaterialSymbol icon="add" />}
                        onClick={handleOpenModal}
                      >
                        Add field
                      </Button>
                    </Box>
                    }
                  </Box> */}
              </Box>

              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Notes" />
                <Box sx={{ width: '100%', paddingTop: '15px' }}>
                  <RichTextInput id="notes" name="notes" control={control} />
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', marginTop: '20px' }}>
                <Box sx={{display:'flex', alignItems:'center', width:'25%', gap:3}}>
                  <DefaultButton 
                    text='Update'
                    onClick={handleFormSubmit}
                    isLoading={isUpdating}
                  />
                  <DefaultButton 
                    text='Cancel'
                    onClick={() => navigate(-1)}
                    variant='outlined'
                    disable={isUpdating}
                  />
                </Box>
                <Box sx={{display:'flex', alignItems:'center', gap:3}}>
                  <APIconButton
                    text='Move to trash'
                    isRounded={false}
                    color={'secondary'}
                    icon={<MaterialSymbol icon='delete' />}
                    isDisabled={isUpdating}
                    onClick={() => { setOpenDeleteDialog(true); }}
                  />
                  {/* <APIconButton
                    text='Next'
                    isRounded={false}
                    variant='text'
                    icon={<MaterialSymbol icon='arrow_forward_ios' />}
                    isDisabled={isUpdating}
                    onClick={() => { setOpenDeleteDialog(true); }}
                  /> */}
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: '20%' }}>
              <Box sx={{ height: '120px'}}>
                <ImagePreview onAttachCompressedImages={handleAttachCompressedImages} onOpenModal={openImagePreview} onModalClose={handleImagePreviewClose}/>
                <Typography sx={{ fontSize: '12px', color: '#53575A' }}>PNG, JPEG, GIF, HEIC</Typography>
              </Box>
              <Box sx={{ mt: '47px' }}>
                <Grid container rowSpacing={1} columnSpacing={0.5}>
                  {displayedImages.slice(0,10).map((image) => (
                    <Grid item xs={6} key={image.url}>
                      <Box sx={{width:'151px',height:'151px'}}>
                        <img loading='lazy' src={image.url} alt={image.filename} width={'100%'} height={'100%'} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {displayedImages.length > 10 && !showAllImages && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={handleViewAllImages} variant="text">
                      View all images
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
      </Box>
      <ImagePreviewModal
        onOpen={openImagePreview}
        images={displayedImages}
        onClose={handleImagePreviewClose}
        onAttach={handleImagePreviewClose}
      />
      <DeleteConfirmationDialog
        title="Move asset to trash"
        content={'Are you sure you want to move this asset to trash?'}
        open={openDeleteDialog}
        onCancel={handleCancelDelete}
        onDelete={handleDelete}
        isDeleting={isAssetDeleting}
      />
      <AssetComment onOpen={openCommentPanel} onClose={()=>{setOpenCommentPanel(false)}} asset={assetData}/>
      <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                  }}
                >
                  <Typography sx={{ mb: 1, fontSize:'12px', color:'#4F4F4F' }}>
                    Enter field name
                  </Typography>
                  <TextField
                    label="Field Name"
                    size='small'
                    fullWidth
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <Box sx={{display:'flex', alignItems:'center', gap:2, mt:3}}>
                    <APIconButton
                        text="Add"
                        isRounded
                        icon={<MaterialSymbol icon="add" />}
                        onClick={handleAddField}
                    />
                    <APIconButton
                        text="Cancel"
                        isRounded
                        icon={<MaterialSymbol icon="close" />}
                        onClick={handleCloseModal}
                        extrastyles={{
                          backgroundColor:'#F2F2F2', 
                          color:'#19161D', 
                          '&:hover': {
                              backgroundColor: '#f0f0f0',
                          }
                        }}
                    />
                  </Box>
                </Box>
      </Modal>
    </Box>
  );
};

export default EditAssetForm;
